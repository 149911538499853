import slimSelect from 'slim-select'

(function () {
  const init = (element, placeholder = 'Select Value') => {
    if (element) {
      return new slimSelect({
        select: element,
        settings: {
          placeholderText: placeholder,
          maxValuesShown: 100000
        }
      })
    }

    document.querySelectorAll('.slim-select').forEach(function(elements) {
      new slimSelect({
        select: elements,
        settings: {
          maxValuesShown: 100000,
        }
      });}
    )
  }

  const SlimSelect = { init }

  if (typeof window != 'undefined') {
    window.SlimSelect = SlimSelect
  }

  return SlimSelect;
})();
